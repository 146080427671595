var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('router-link',{staticClass:"btn btn-primary btn-sm float-end",attrs:{"to":{ name: 'dashboard.organizations.create' }}},[_c('i',{staticClass:"fa fa-plus me-2"}),_vm._v("Add New")]),_c('h1',{staticClass:"h3 mb-4 text-gray-800"},[_vm._v("My Organizations")]),(! _vm.loading)?_c('div',{staticClass:"row"},[_vm._l((_vm.orgs),function(org,i){return _c('div',{key:`org-${i}`,staticClass:"col-md-3 mb-3"},[_c('div',{staticClass:"card shadow"},[_c('div',{staticClass:"card-body text-center"},[_c('router-link',{attrs:{"to":{
              name: 'dashboard.organizations.show',
              params: { id: org.id },
            }}},[_c('v-lazy-image',{staticClass:"shadow mb-3 rounded",staticStyle:{"width":"150px"},attrs:{"src":org.logo,"src-placeholder":"/assets/organization.png"}})],1),_c('router-link',{attrs:{"to":{
              name: 'dashboard.organizations.show',
              params: { id: org.id },
            }}},[_c('h4',[_vm._v(_vm._s(org.name))])]),_c('p',{staticClass:"mb-2"},[_c('i',{staticClass:"fa fa-building me-2"}),_vm._v(_vm._s(org.industry)+" ")]),_c('p',{staticClass:"mb-2"},[_c('i',{staticClass:"fa fa-map-marker me-2"}),_vm._v(_vm._s(org.location)+" ")]),_c('p',{staticClass:"mb-2"},[_c('i',{staticClass:"fa fa-calendar me-2"}),_vm._v(_vm._s(org.created)+" ")])],1)])])}),(_vm.orgs.length == 0)?_c('div',{staticClass:"col-md-12"},[_c('alert',{attrs:{"type":"info"}},[_vm._v("You have not added any organizations.")])],1):_vm._e()],2):_vm._e(),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }