<template>
  <div>
    <router-link
      :to="{ name: 'dashboard.organizations.create' }"
      class="btn btn-primary btn-sm float-end"
      ><i class="fa fa-plus me-2"></i>Add New</router-link
    >
    <h1 class="h3 mb-4 text-gray-800">My Organizations</h1>
    <div class="row" v-if="! loading">
      <div class="col-md-3 mb-3" v-for="(org, i) in orgs" :key="`org-${i}`">
        <div class="card shadow">
          <div class="card-body text-center">
            <router-link
              :to="{
                name: 'dashboard.organizations.show',
                params: { id: org.id },
              }"
            >
              <v-lazy-image :src="org.logo" class="shadow mb-3 rounded" style="width: 150px" src-placeholder="/assets/organization.png" />
            </router-link>
            <router-link
              :to="{
                name: 'dashboard.organizations.show',
                params: { id: org.id },
              }"
            >
              <h4>{{ org.name }}</h4>
            </router-link>
            <p class="mb-2">
              <i class="fa fa-building me-2"></i>{{ org.industry }}
            </p>
            <p class="mb-2">
              <i class="fa fa-map-marker me-2"></i>{{ org.location }}
            </p>
            <p class="mb-2">
              <i class="fa fa-calendar me-2"></i>{{ org.created }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="orgs.length == 0">
        <alert type="info">You have not added any organizations.</alert>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      orgs: [],
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/dashboard/organizations").then((response) => {
        this.orgs = response.data.orgs;
        this.loading = false;
      });
    },
  },
};
</script>
